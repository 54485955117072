import shared from './shared/_shared.js'

const colors = {
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',

  colorBorderLighter: '#f5f5f5',
  colorBorderLight: '#F0F0F0',
  colorGrey: '#979797',
  colorGreyDark: '#5D5D5D',
  colorGreyDarker: '#2A2A2A',
  colorGreyDarkest: '#1a1a1a',

  colorYellow: '#FFF9AA',
  colorOrange: '#FF825C',
}

const main = {
  ...colors,
  ...shared,
}

export default main
